<template>
  <div class="wrapper">
    <van-empty description="暂不支持此办事项办理" />
  </div>
</template>
<script>
export default {
  daat() {
    return {};
  },
  mounted() {
    this.$emit("authCheck", false);
  },
};
</script>
<style lang="scss" scoped>
</style>